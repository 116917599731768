/* Common styles */
.MainPage,
.LoginPage,
.SignUp {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  font-family: 'Poppins', sans-serif;
}

.MainPage-container,
.LoginPage-container,
.SignUp-container {
  border-radius: 15px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 90%;
  max-width: 700px;
  padding: 40px;
  font-family: 'Poppins', sans-serif;
}

/* MainPage specific styles */
.MainPage {
  background-image: linear-gradient(-45deg, #fff5f6, #ffe5e7, #fff5f6, #ffe5e7);
}

/* LoginPage and SignUp specific styles */
.LoginPage,
.SignUp {
  background-color: white;
  font-family: 'Poppins', sans-serif;
}

.LoginPage-container,
.SignUp-container {
  width: 90%;
  max-width: 550px;
  padding: 40px 30px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fea0a5;
}

.LoginPage-container {
  border: 3px solid #ffffff;
}

.SignUp-container {
  width: 100%;
  max-width: 550px;
  padding: 40px 30px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  border: 3px solid #ffffff;
  border-radius: 15px;
}

.LoginPage-header,
.SignUp-header {
  margin-bottom: 30px;
  color: white;
}

.LoginPage-header h1,
.SignUp-header h1 {
  color: #ffffff;
  margin: 20px 0 5%;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-size: 2rem;
}

/* Button styles */
.MainPage-SignIn-Button,
.MainPage-SignUp-Button,
.LoginPage-SignIn-Button,
.LoginPage-SignUp-Button {
  font-family: 'Poppins', sans-serif;
  background-color: #fea0a5;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 15px 0;
  display: inline-block;
  width: 100%;
  max-width: 250px;
}

.LoginPage-SignIn-Button,
.LoginPage-SignUp-Button {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
}

.MainPage-SignIn-Button:hover,
.MainPage-SignUp-Button:hover,
.LoginPage-SignIn-Button:hover,
.LoginPage-SignUp-Button:hover {
  background-color: #ffffff;
  color: #fea0a5;
}

/* Form element styles */
input,
/* ... (previous styles remain the same) ... */

/* Form element styles */
input,
select,
textarea {
  font-family: 'Poppins', sans-serif;
  display: block;
  background-color: #ffffff;
  margin: 15px auto;
  padding: 12px 15px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #ffffff;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  color: #000000;
}

/* Specific styles for select element */
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23fea0a5' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 40px;
  height: auto;
}

select option {
  color: #fea0a5;
  background-color: #ffffff;
  padding: 12px;
  white-space: normal;
  word-wrap: break-word;
}

/* Placeholder option style */
select option[value=""][disabled] {
  color: rgba(254, 160, 165, 0.7);
}

/* Focus styles */
select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(254, 160, 165, 0.5);
}

/* Hover styles */
select:hover {
  border-color: #fea0a5;
}

/* ... (rest of the CSS remains the same) ... */
/* Gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .LoginPage-container,
  .SignUp-container {
    padding: 30px 20px;
  }

  input,
  select,
  textarea,
  .MainPage-SignIn-Button,
  .MainPage-SignUp-Button,
  .LoginPage-SignIn-Button,
  .LoginPage-SignUp-Button {
    max-width: 100%;
  }

  .LoginPage-header h1,
  .SignUp-header h1 {
    font-size: 1.8rem;
  }
}

/* SignUp specific styles */
.SignUp {
  background-color: #fea0a5;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
}

.SignUp-container .logo {
  width: 80%;
  max-width: 200px;
  height: auto;
  margin: 0 auto 1.5rem;
}

.SignUp-header p {
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.SignUp input,
.SignUp select,
.SignUp textarea {
  width: 100%;
  padding: 0.6rem;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  color: #ffffff;
  margin-bottom: 1rem;
}

.SignUp input::placeholder,
.SignUp textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.SignUp select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
}

.SignUp select option {
  background-color: #fea0a5;
  color: #ffffff;
}

.SignUp .LoginPage-SignIn-Button {
  background-color: #ffffff;
  color: #fea0a5;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.SignUp .LoginPage-SignIn-Button:hover {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.SignUp .back-to-home {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 1rem;
  align-self: flex-start;
}

.SignUp .back-to-home:hover {
  text-decoration: underline;
}

.signup-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fea0a5;
  padding: 2rem;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.signup-container {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.signup-container .logo {
  width: 80px;
  height: auto;
  margin-bottom: 2rem;
}

.signup-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-container h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.signup-container p {
  margin-bottom: 1.5rem;
}

.signup-container input,
.signup-container select,
.signup-container textarea {
  width: 100%;
  padding: 0.6rem;
  margin-bottom: 1rem;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.signup-container input::placeholder,
.signup-container textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.signup-container select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
}

.signup-container .btn {
  background-color: #ffffff;
  color: #fea0a5;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  min-width: 140px;
}

.signup-container .btn:hover {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.back-to-home {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 2rem;
}

.back-to-home:hover {
  text-decoration: underline;
}
