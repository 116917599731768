@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
  --color-primary: #d88aa3;
  --color-primary-light: #e6899f;
  --color-neutral-light: #f7f7f7;
  --color-accent: #f15654;
  --color-text: #ffffff;
  --color-background: #fea0a5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: 1.6;
}

.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  width: 100%;
}

.logo-container {
  flex: 1;
  max-width: 300px;
}

.logo {
  width: 100%;
  max-width: 250px;
  min-width: 150px;
  height: auto;
  max-height: 60px;
  object-fit: contain;
}

.company-name {
  font-size: 1.5rem;
  font-weight: 600;
}

.nav-links {
  flex: 1;
  display: flex;
  justify-content: center;
}

.auth-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 3rem 5% 1rem;
  align-items: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.image-and-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  width: 90%;
  margin-bottom: .1rem;
}

.image-content {
  flex: 1;
  max-width: 55%;
}

.dashboard-image {
  width: 100%;
  height: auto;
}

.text-content {
  flex: 1;
  max-width: 50%;
  margin-bottom: 8%;
  padding-left: 5%;
}

.main-title {
  font-weight: 500;
  text-align: center;
  font-size: calc(2vw + 1rem);
  margin: -.5rem 0 4rem;
  max-width: 90%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  opacity: 0;
  animation: fadeInSlide 2s ease-out 3s forwards;
}

.taglines h2 {
  font-size: calc(3.5vw + 1rem);
  font-weight: 550;
  margin-bottom: .8rem;
  line-height: 1.1;
  white-space: nowrap;
  opacity: 0;
  position: relative;
}

.say-goodbye {
  font-size: calc(2vw + 0.5rem);
  font-weight: 400;
  margin-bottom: 1rem;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.tagline-1 { animation: fadeIn 1s ease-in-out 0.5s forwards; }
.tagline-2 { animation: fadeIn 1s ease-in-out 1.5s forwards; }
.tagline-3 { animation: fadeIn 1s ease-in-out 2.5s forwards; }

.strikethrough {
  position: relative;
  display: inline-block;
}

.strikethrough::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 3px;
  background-color: var(--color-text);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-out;
}

.tagline-1 .strikethrough::after { animation: strikethrough 0.5s ease-out 4s forwards; }
.tagline-2 .strikethrough::after { animation: strikethrough 0.5s ease-out 4s forwards; }
.tagline-3 .strikethrough::after { animation: strikethrough 0.5s ease-out 4s forwards; }

@keyframes strikethrough {
  to { transform: scaleX(1); }
}
.cta-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer {
  text-align: center;
  padding: 1rem 5%;
}

.brand-logos {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.brand-logos img {
  height: 30px;
  opacity: 0.8;
  filter: brightness(0) invert(1);
  transition: opacity 0.3s ease;
}

.brand-logos img:hover {
  opacity: 1;
}

.btn {
  background-color: transparent;
  color: var(--color-text);
  border: 2px solid var(--color-text);
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
}

.btn-primary {
  background-color: var(--color-text);
  color: var(--color-background);
}

.btn-primary:hover {
  background-color: transparent;
  color: var(--color-text);
}

.btn-secondary:hover {
  background-color: var(--color-text);
  color: var(--color-background);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .image-and-text-container {
    flex-direction: column;
    text-align: center;
  }

  .image-content, .text-content {
    max-width: 75%;
  }

  .cta-buttons {
    justify-content: center;
  }

  .btn {
    width: auto;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .header {
    flex-wrap: wrap;
  }

  .logo-container {
    flex: 0 0 100%;
    max-width: none;
    margin-bottom: 1rem;
  }

  .nav-links {
    order: 3;
    flex: 0 0 100%;
    justify-content: center;
    margin-top: 1rem;
  }

  .auth-buttons {
    flex: 0 0 100%;
    justify-content: center;
  }

  .btn {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
