@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fea0a5;
  padding: 2rem;
  color: #ffffff;
}

.login-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.login-container {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.logo {
  width: 80%;
  max-width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
}

.input-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
  font-weight: 500;
}

input {
  width: 100%;
  padding: 0.6rem;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  color: #ffffff;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.btn {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 10px 0;
  display: inline-block;
  min-width: 140px;
  font-family: 'Poppins', sans-serif;
}

.btn-primary {
  background-color: #ffffff;
  color: #fea0a5;
}

.btn-primary:hover {
  background-color: transparent;
  color: #ffffff;
}

.error-message {
  color: #ffffff;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.back-to-home {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: opacity 0.3s;
}

.back-to-home:hover {
  opacity: 0.8;
  text-decoration: underline;
}
